@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  background: -moz-linear-gradient(90deg, rgba(43, 51, 52, 1) 0%, rgba(23, 63, 94, 1) 77%);
  background: -ms-linear-gradient(90deg, rgba(43, 51, 52, 1) 0%, rgba(23, 63, 94, 1) 77%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #000000), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(90deg, rgba(43, 51, 52, 1) 0%, rgba(23, 63, 94, 1) 77%);
  background: -o-linear-gradient(90deg, rgba(43, 51, 52, 1) 0%, rgba(23, 63, 94, 1) 77%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#000000, endColorstr=transparent);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#000000, endColorstr=transparent)";
  background: linear-gradient(90deg, rgba(43, 51, 52, 1) 0%, rgba(23, 63, 94, 1) 77%);
  font-family: Source Code Pro, monospace;
  margin: 0;
  padding: 0;
  z-index: 0.1;
  overflow-x: hidden !important;
}

@keyframes fadeleft {
  from {
    margin-left: 0;
    opacity: 0%;
  }

  to {
    margin-left: 5%;
    opacity: 100%;
  }
}

@keyframes fadedown {
  from {
    margin-top: 0;
    opacity: 0%;
  }

  to {
    margin-top: 3%;
    opacity: 100%;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #173F5E;
}

::-webkit-scrollbar-thumb {
  background: #A5FF90;
}

::-webkit-scrollbar-thumb:hover {
  background: #76b966;
}

.row {
  margin-left: 2.5%;
  z-index: 1;
}

.row,
.row>.column {
  padding: 12px;
}

.column {
  float: left;
  width: 24%;
  z-index: 1;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  margin-top: -50px;
}

.content {
  backdrop-filter: blur(4px);
  background-color: rgba(57, 91, 100, 0.5);
  border-radius: 10px;
  padding: 10px;
  transition: opacity 0.2s, transform 0.2s ease;
  -webkit-transition: -webkit-transform 0.2s ease;
  -moz-transition: -moz-transform 0.2s ease;
  height: 35vw;
}

.content:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.content:active {
  transform: rotateY(180deg);
}

@media screen and (max-width: 800px) {
  .content:active {
    transform: scale(1.05);
  }
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  backdrop-filter: blur(4px);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

@keyframes portfoliooverlayfadedown {
  from {
    top: -10%;
    opacity: 0.2;
  }

  to {
    top: 7.5%;
    opacity: 1;
  }
}

@keyframes portfoliooverlayfadeup {
  from {
    top: 7.5%;
    opacity: 1;
    display: block;
    z-index: 1;
  }

  to {
    top: -10%;
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

.portfoliohidden {
  animation-name: portfoliooverlayfadeup;
  animation-duration: 0.4s;
  animation-timing-function: ease;
  opacity: 0;
  position: fixed;
  width: 70%;
  height: 85%;
  top: -10%;
  bottom: 7.5%;
  left: 15%;
  right: 15%;
  background-color: #395B64;
  z-index: -1;
  padding: 10px 10px 10px 10px;
  border-radius: 25px 0px;
}

.portfoliooverlay {
  position: fixed;
  display: none;
  width: 70%;
  height: 85%;
  top: 7.5%;
  bottom: 7.5%;
  left: 15%;
  right: 15%;
  opacity: 1;
  background-color: #395B64;
  z-index: 999;
  padding: 10px 10px 10px 10px;
  border-radius: 25px 0px;
  animation-name: portfoliooverlayfadedown;
  animation-duration: 0.4s;
  animation-timing-function: ease;
}

.portfolioheader {
  font-size: max(4vw, 30px);
  text-align: center;
  margin-top: -40px;
  color: #a5ff90;
}

.portfoliodesc {
  font-size: 1.6vw;
  margin-left: 5px;
}

.portfoliolongdesc {
  position: absolute;
  width: 99%;
  height: 80%;
  right: 0;
  top: 8%;
  font-size: max(1.4vw, 15px);
  white-space: pre-wrap;
  overflow: auto;
  overflow-x: hidden !important;
}

.portfoliobefore {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #173F5E;
  z-index: 10;
  font-size: 1.6vw;
  padding: 15px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 8%;
  border-radius: 25px 0px 0px 0px ;
  align-items: center;
}

.portfolioafter {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #173F5E;
  z-index: 10;
  font-size: max(1.6vw, 20px);
  font-weight: bold;
  width: 100%;
  height: 12%;
  align-items: center;
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.portfoliodiv {
  display: flex;
  justify-content: space-between;
}

.portfolioheaderdiv {
  width: 40%;
}

.portfoliologo {
  width: 90%;
  margin-left: 5%;
  padding: 30px;
  border-radius: 60px;
}

@media screen and (max-width: 1000px) {
  .column {
    width: 48%;
  }

  .content {
    height: 50vw;
  }

  .portfolioheader {
    font-size: 4vw !important;
  }

  .portfoliodesc {
    font-size: 2.5vw !important;
  }

  .portfoliologo {
    width: 70%;
    margin-left: 15%;
  }

  .portfolioafter {
    height: 20%;
    display: block;
    text-align: center;
    line-height: 20px;
    align-items: center;
  }

  .portfoliolongdesc {
    height: 71.9%
  }
}

@media screen and (max-width: 600px) {
  .column {
    width: 96%;
  }

  .content {
    height: 90vw;
  }

  .portfolioheader {
    font-size: 40px !important;
  }

  .portfoliodesc {
    font-size: 4vw !important;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

@media only screen and (max-width: 1000px) {
  .container {
    display: inline-block;
  }

  .right {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    width: 88vw !important;
  }

  .left {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    width: 88vw !important;
  }
}

background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
}

.left {
  width: 43vw;
  line-height: 70px;
  margin-left: 5vw;
  margin-top: 3vw;
  padding: 15px 30px;
  border-radius: 40px 0px 0px 0px;
  animation-name: fadeleft;
  animation-duration: 2s;
  animation-timing-function: ease;
  z-index: 1;
  backdrop-filter: blur(4px);
  background-color: rgba(57, 91, 100, 0.5);
}

.right {
  margin-right: 5vw;
  width: 43vw;
  line-height: 70px;
  margin-top: 3%;
  padding: 15px 30px;
  border-width: 3px;
  border-top: 5px solid #a5ff90;
  animation-name: fadedown;
  animation-timing-function: ease;
  animation-duration: 2s;
  z-index: 1;
  border-radius: 0px 0px 40px 0px;
  backdrop-filter: blur(4px);
  background-color: rgba(57, 91, 100, 0.5);
}

@media only screen and (max-width: 600px) {
  .left {
    margin-left: 0%;
  }

  .right {
    margin-right: 0%;
  }
}

.btn {
  font-family: Source Code Pro, monospace;
  font-weight: bold;
  background-color: transparent;
  border-radius: 3px;
  border: 3px solid #a5ff90;
  color: #A5FF90;
  cursor: pointer;
  margin-top: 25px;
  padding: 0.3vw 0.6vw;
  font-size: 32px;
  transition: background-color 0.3s ease, color 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:hover {
  color: #395B64;
  background-color: #A5FF90;
}

.btn:active {
  transform: scale(0.95);
}


h1 {
  color: #fff;
  font-size: 32px;
  z-index: 100;
}

a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
  word-wrap: break-word;
}

a:hover {
  color: #A5FF90;
}

footer {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #2A3334;
  color: #fff;
  font-weight: bold;
  align-items: center;
  font-size: max(1.5vw, 15px);
  margin-top: 5px;
  margin-bottom: -1000%;
  padding: 10px;
}